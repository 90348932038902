import { Loader } from "@react-three/drei";

const OwnLoader = () => {
  return (
    <Loader
      dataStyles={{
        color: "#012749",
      }}
      containerStyles={{
        backgroundColor: "white",
        zIndex: 123456789,
      }}
    />
  );
};

export default OwnLoader;
